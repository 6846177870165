<template>
  <div class="wrapper">
    <div class="watermark" ref="watermark"></div>
    <div class="top">
      <div class="baseinfo">
        <span>{{ userinfo.nickname }}</span>
        <span>{{ userinfo.dept || "" }}</span>
      </div>
      <div class="taskinfo">
        <div class="todaytask">
          <van-tag type="primary" size="large">今日任务</van-tag>
          <van-circle
            :value="currentRate"
            :speed="1"
            :rate="100"
            :color="gradientColor"
            :text="finishcount + '/' + totalcount"
          >
          </van-circle>
        </div>

        <div class="countdown">
          <van-count-down
            :time="time * 1000"
            format="ss"
            ref="countDown"
            :auto-start="false"
            @finish="timerfinish"
          />
        </div>
      </div>
    </div>
    <div class="calendar">
      <van-calendar
        title="日历"
        :poppable="false"
        :show-confirm="false"
        :readonly="true"
        :default-date="null"
        :max-date="maxdate"
        :min-date="mindate"
        :style="{ height: '500px' }"
        :formatter="formatter"
      />
    </div>
    <div class="frame">
      <!-- <iframe :src="currenttask.url" frameborder="0" ref="iframe"></iframe> -->
      <img v-if="isios" :src="currenttask.url" />
      <iframe
        :src="currenttask.url"
        frameborder="0"
        ref="iframe"
        v-else
      ></iframe>
    </div>
    <div class="apptaskinfo" v-if="apptask.show">
      <p>掌上温岭App任务</p>
      <p>{{ apptask.userinfo.mobile }}|{{ apptask.userinfo.nick_name }}</p>
      <p>浏览:{{ apptask.tasksummary.read }}</p>
      <p>点赞:{{ apptask.tasksummary.hot }}</p>
      <p>评论:{{ apptask.tasksummary.comment }}</p>
      <p>转发:{{ apptask.tasksummary.share }}</p>
    </div>
  </div>
</template>

<script>
const last3day = new Date(new Date().getTime() - 3 * 3600 * 24 * 1000);
import moment from "moment";
import { doview, index } from "@/api/task";
export default {
  name: "worker",
  async created() {
    var user_agent = navigator.userAgent.toLowerCase();
    this.isios = user_agent.match(/iphone|ipod|ipad/gi);
    const token = localStorage.getItem("token");
    if (token == null) {
      this.$router.push({ name: "login" });
    }
    const userinfostr = localStorage.getItem("userinfo");
    if (userinfostr != null) {
      this.userinfo = JSON.parse(userinfostr);
    }

    if (window.electronAPI) {
      this.apptask.show = true;
      const result = await window.electronAPI.startApptask(
        this.userinfo.username
      );
      this.apptask.userstate = result.status;
      this.apptask.userinfo = result;

      window.electronAPI.handletaskexecinfo((event, data) => {
        if (data.read.success) {
          this.apptask.tasksummary.read += 1;
        } else {
          this.$toast({ message: data.read.info.message, position: "bottom" });
        }

        if (data.hot.success) {
          this.apptask.tasksummary.hot += 1;
        } else {
          this.$toast({ message: data.hot.info.message, position: "bottom" });
        }
        if (data.share.success) {
          this.apptask.tasksummary.share += 1;
        } else {
          this.$toast({ message: data.share.info.message, position: "bottom" });
        }
        if (data.comment.success) {
          this.apptask.tasksummary.comment += 1;
        } else {
          this.$toast({
            message: data.comment.info.message,
            position: "bottom",
          });
        }
      });
    }
  },
  mounted() {
    this.fetchpages();

    this.$refs.watermark.style.backgroundImage = `url("data:image/svg+xml;base64,${btoa(
      this.markTemp(this.userinfo.username)
    )}")`;
  },

  data() {
    return {
      isios: false,
      apptask: {
        show: false,
        userstate: 0,
        userinfo: {},
        tasksummary: {
          read: 0,
          hot: 0,
          comment: 0,
          share: 0,
          read10s: 0,
        },
      },

      gradientColor: {
        "0%": "#3fecff",
        "100%": "#6149f6",
      },
      finishcount: 0,
      totalcount: 0,
      time: 20,
      finishlist: [],
      pagelist: [],
      currenttask: {},
      mindate: new Date(
        last3day.getFullYear(),
        last3day.getMonth(),
        last3day.getDate()
      ),
      userinfo: {},
      maxdate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
    };
  },
  computed: {
    currentRate() {
      return (this.finishcount / this.totalcount) * 100;
    },
  },
  methods: {
    markTemp(text) {
      return `<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='100px' width='100px'><text transform='translate(20, 100) rotate(-45)' fill='rgba(45,45,45,0.9)' font-size='10' >${text}</text></svg>`;
    },

    formatter(day) {
      const currentdatestr = moment(day.date).format("YYYY-MM-DD");
      const finish = this.finishlist.find((v) => v.date == currentdatestr);
      if (finish) {
        day.topInfo = finish.count;
        day.bottomInfo = finish.state == 1 ? "已完成" : "";
      }

      return day;
    },
    starttask() {
      if (this.finishcount < this.totalcount) {
        this.currenttask = this.pagelist.shift();
        this.$toast({
          message: "正在浏览:" + this.currenttask.list_title,
          position: "top",
        });
        this.$refs.countDown.reset();
        this.$refs.countDown.start();
      } else {
        this.$toast({ message: "当天任务已完成", position: "bottom" });

        setTimeout(() => {
          this.fetchpages();
        }, 5 * 60 * 1000);
      }
    },
    fetchpages() {
      index().then((res) => {
        if (res.code == 1) {
          if (res.data.candoview == 1) {
            this.finishlist = res.data.finishlist;
            this.pagelist = res.data.pagelist;
            if (this.finishlist.length > 0) {
              this.pagelist = this.pagelist.filter(
                (v) => res.data.viewlist.findIndex((u) => u.pageid == v.id) < 0
              );
            }
            if (window.electronAPI) {
              window.electronAPI.doApptask(
                res.data.pagelist.filter((v) => v.type == 2)
              );
            }
            this.totalcount = this.pagelist.length;
            this.starttask();
          } else {
            setTimeout(() => {
              this.fetchpages();
            }, 5 * 60 * 1000);
          }
        } else {
          this.$toast(res.msg);
        }
      });
    },
    timerfinish() {
      //   this.$refs.countDown.reset();
      this.reportview();
    },
    reportview() {
      doview({ id: this.currenttask.id }).then((res) => {
        if (res.code == 1) {
          this.finishcount += 1;
          const currentdatestr = moment(this.currenttask.sort_number).format(
            "YYYY-MM-DD"
          );
          let finish = this.finishlist.find((v) => v.date == currentdatestr);
          if (finish) {
            finish.count += 1;
          }
          this.starttask();
        } else {
          setTimeout(this.reportview(), this.time * 1000);
          this.$toast(res.msg + "\n" + this.time + "秒后重试");
          // this.$toast("15秒后重试");
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}

.wrapper {
  height: 100vh;
  width: 100vw;
  padding: 0 30px;

  .watermark {
    height: 100vh;
    width: 100vw;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.1;
    background-repeat: repeat;
  }

  .top {
    height: 320px;
    width: 100%;
    background-color: #127bca;
    display: flex;
    flex-direction: column;

    .baseinfo {
      height: 120px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        width: 50%;
        font-weight: bold;
        font-size: 36px;
        color: #fff;
        text-align: center;
      }
    }
  }

  .taskinfo {
    display: flex;

    .todaytask {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

    .countdown {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .frame {
    iframe {
      width: 100%;
    }
  }
}

.apptaskinfo {
  position: fixed;
  left: 0;
  top: 400px;
  width: 320px;
  height: 240px;
  display: flex;
  flex-direction: column;
  background-color: rgba(1, 1, 1, 0.6);

  p {
    font-size: 26px;
    display: flex;
  }
}

/deep/.van-count-down {
  font-size: 46px;
}
</style>
